<template>
  <div id="RechargeRecord">
    <van-nav-bar
      :fixed="true"
      z-index="10"
      placeholder
      :title="$t('My.PersonalInformation.RechargeRecord.title')"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- <van-tabs v-model="active" @click="ActiveEvent">
      <van-tab :title="$t('My.PersonalInformation.RechargeRecord.Recharge')"> -->
    <div class="tabs-item">
      <div class="item-content" v-for="(item, index) in newList" :key="index">
        <div>
          {{ $t('My.PersonalInformation.RechargeRecord.Amount')
          }}{{ item.amount }}
        </div>
        <div>
          {{ $t('My.PersonalInformation.RechargeRecord.Order')
          }}{{ item.item_id }}
        </div>
        <div>
          {{ $t('My.PersonalInformation.RechargeRecord.Status')
          }}<span>{{ item.stateTxt }}</span>
        </div>
      </div>
    </div>
    <!-- </van-tab>
    </van-tabs> -->
    <!-- 底部分页的东西 -->
    <div class="bottom-content">
      <div class="Text">
        Total {{ Paging.Total }} records, each page displays
        {{ Paging.PageSize }}, total {{ Paging.Num }} pages.
      </div>
      <van-pagination
        prev-text="<"
        next-text=">"
        v-model="Paging.CurrentPage"
        mode="simple"
        :total-items="Paging.Total"
        :items-per-page="Paging.PageSize"
        @change="PageEvent"
      />
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Tab,
  Tabs,
  Image,
  NoticeBar,
  Pagination,
  Toast,
} from 'vant'
import { REQUEST_API } from '@/http/api'
import { countTime, getMyTime, deciTurnMoney } from '@/utils/tools'
export default {
  name: 'RechargeRecord',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      show: false, // 控制日期按钮弹出框的
      // 日期选择的值
      active: 0, // 标签选中的值
      TabelData: [
        {
          money: '100.000',
          Num: 'SY201105021116039',
          Status: 'Pending review',
        },
      ], // 列表渲染的数据
      // 分页的数据
      Paging: {
        PageSize: 10, // 当前一页多少条
        CurrentPage: 1, // 当前页
        Num: '', // 分页的数量
        Total: 0, // 总条数
      },
      list: [],
      userInfo: [],
      pageNo: 1,
    }
  },
  computed: {
    newList() {
      /**
       * <= 1待审核
       * 2成功
       */
      let list = this.list.map((_) => ({
        ..._,
        stateTxt: _.status <= 1 ? 'to audit' : 'succeed',
        amount: deciTurnMoney(_.amount)
      }))
      return list
    },
  },
  created() {
    this.init()
    this.recordRechargeList()
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    // 顶部标签的点击事件
    ActiveEvent() {
      this.Paging.CurrentPage = 1
    },
    // 分页的点击事件
    PageEvent() {
      this.list = []
      this.recordRechargeList()
    },
    recordRechargeList() {
      let form = {
        page: this.Paging.CurrentPage,
        pageNum: 10,
        uid: this.userInfo[1].data.player.uid,
        cmd: '201018',
      }
      REQUEST_API(form)
        .then((r) => {
          console.log('充值记录', r.data.notifyList[0].data.data)
          if (!r.data.sc) {
            if (r.data.notifyList[0].data.count) {
              this.list.push(...r.data.notifyList[0].data.data)
              this.finished =
                r.data.notifyList[0].data.page ===
                r.data.notifyList[0].data.pageSize
              this.pageNo++
              if (this.finished) {
                this.loading = false
              }
              this.Paging.Num = Math.ceil(
                r.data.notifyList[0].data.count /
                  r.data.notifyList[0].data.PageSize
              )
              this.Paging.Total = r.data.notifyList[0].data.count
            } else {
              this.Paging.Num = 0
              this.Paging.Total = 0
              this.list = []
              this.loading = false
            }
          } else {
            this.Paging.Num = 0
            this.Paging.Total = 0
            this.list = []
            this.loading = false
            Toast(this.$t('Hint.failed'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }, // 充值记录
    /** 其他 **/
    init() {
      this.userInfo = JSON.parse(localStorage.getItem('user-info'))
    },
  },
}
</script>

<style lang="less">
#RechargeRecord {
  width: 100%;
  height: 100%;
  .tabs-item {
    width: 100%;
    > .item-content {
      width: calc(100% - 1.25rem);
      border-bottom: 1px solid #ccc;
      padding: 0.625rem;
      > div {
        font-size: 0.75rem;
        line-height: 1.25rem;
        font-weight: 400;
        > span {
          color: red;
        }
      }
      > div:nth-child(1) {
        font-size: 0.875rem;
      }
    }
  }
  .bottom-content {
    width: 100%;
    > .Text {
      width: 100%;
      text-align: center;
      line-height: 1.25rem;
      color: #666;
      font-size: 0.75rem;
    }
  }
  .van-tabs {
    .van-tabs__wrap {
      border-bottom: 1px solid #eee;
    }
  }
}
</style>